.cart {
  &-table {
    overflow-x: auto;

    &-img {
      height: 8.1rem;
      width: 8.1rem;
      object-fit: cover;
    }
  }
  &-right {
    color: var(--white);
    padding-top: 37px;
    &-head {
      font-weight: 600;
      font-size: 10px;
      line-height: 160%;
      text-align: right;
      letter-spacing: 0.08em;
      text-transform: capitalize;
    }
    &-line {
      height: 4px;
      width: 100%;
      border: 0.5px solid var(--white1);
      position: relative;
      span {
        position: absolute;
        background: #fff;
        width: 16.1rem;
        height: 0.3rem;
      }
    }
  }
  &-total {
    &-inner {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      border-top: 0.05rem solid rgba(255, 255, 255, 0.7);
      padding: 1rem 0;
      span {
        font-size: 14px;
      }
      .orange {
        color: var(--theme);
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;

    tr {
      width: 100%;
      vertical-align: top;
      padding: 10px;
      display: inline-block;
      &:first-child {
        border-bottom: 0.05rem solid rgba(255, 255, 255, 0.7);
      }
    }

    th {
      width: 161px;
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);
      &:last-child {
        text-align: right;
      }
    }

    td {
      opacity: 0.5;
      font-weight: 600;

      letter-spacing: 0.09em;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 15px;
      color: var(--white);
      min-width: 161px;
      width: 161px;
      &:not(:last-child) {
        padding-right: 3rem;
      }
      &:last-child {
        text-align: right;
      }
      &:first-child {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  .table {
    &-qty {
      font-size: 1.6rem;
      span {
        padding: 0 1rem;
      }
    }
    &-price {
      span {
        width: 8.62px;
        height: 8.62px;
        border-radius: 50%;
        background: #c4c4c4;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
    &-edit {
      max-width: 86px;
      button {
        font-size: 14px;
        line-height: 0;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--white);
        background: none;
        border: none;
      }
    }
  }
}
.btn {
  &-checkout {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    float: right;
    color: var(--theme);
    border: 0.5px solid var(--theme);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem 5rem;
  }
}

.apply-coupon {
  &-heading {
    color: var(--lightgrey);
    font-size: 1.4rem;
  }
  &-form {
    align-items: center;
    justify-content: flex-start;
    & input {
      color: var(--lightgrey);
      background-color: var(--dark) !important;
      border: 1px solid var(--lightgrey) !important;
      border-radius: 5px !important;
      padding: 1rem 1rem !important;
    }
  }
}
.view{
  &-order{
    font-size: 2rem;
    line-height: 2.9rem;
    letter-spacing: 0.09em;
    color: var(--white);
    span{
      color: var(--theme);
    }
  }
  &-head{
    margin-bottom: 1rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
    letter-spacing: 0.09em;
    color: var(--white);
  }
  &-card{
    border-top:none;
    border-bottom: 0.05rem solid rgba(255, 255, 255, 0.7);
    span{
      display: inherit;
    margin-top: 1rem;
    }
  }
}