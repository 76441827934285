button {
  cursor: pointer;
}
input {
  &:focus {
    outline: none;
  }
}
.width{
  &-100 {
    width: 100%;
  }

  &-60{
    width: 60%
  }
}



.flex1 {
  flex: 1;
}
li {
  list-style: none;
}
// .line-through {
//   text-decoration: line-through;
// }
.center {
  text-align: center;
}

[class^="col-"] {
  float: left;
  position: relative;
  padding: 0 1rem;
}
.container {
  max-width: 140rem;
  margin: 0 auto;
  // width: 94%;
  // position: relative;
  margin: 0 auto;
  padding: 0 2.4rem;
  position: relative;
  width: 100%;
}

.row {
  margin-left: -1rem;
  margin-right: -1rem;
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@include respond-from(xl) {
  @include grid-generator(xl, 12);
}

@include respond-from(lg) {
  @include grid-generator(lg, 12);
}

@include respond-from(md) {
  @include grid-generator(md, 12);
}

@include respond-from(sm) {
  @include grid-generator(sm, 12);
}

@include respond-from(xs) {
  @include grid-generator(xs, 12);
}
.text {
  &-right {
    text-align: right;
  }
}
.flex {
  display: flex;
  &-wrap {
    flex-wrap: wrap;
  }
}
.justify {
  &-space {
    justify-content: space-between;
  }
  &-center {
    justify-content: center;
  }
  &-right {
    justify-content: right;
  }
}
.align {
  &-center {
    align-items: center;
  }
  &-start {
    align-items: start;
  }
  &-baseline {
    align-items: baseline;
  }
  &-end {
    align-self: end;
  }
}

.p {
  &0 {
    padding: 0rem !important;
  }
  &1 {
    padding: 1rem;
  }
  &2 {
    padding: 2rem;
  }
  &y {
    &1 {
      padding: 1rem 0;
    }
    &2 {
      padding: 2rem 0;
    }
    &3 {
      padding: 3rem 0;
    }
    &7 {
      padding: 7rem 0;
    }
    &8 {
      padding: 8rem 0;
    }
  }
  &x {
    &1 {
      padding: 0 1rem;
    }
  }
  &l {
    &1 {
      padding-left: 1rem;
    }
    &2 {
      padding-left: 2rem;
    }
    &7 {
      padding-left: 7rem;
    }
  }
  &t {
    &1 {
      padding-top: 1rem;
    }
    &3 {
      padding-top: 3rem;
    }
  }
  &b {
    &1 {
      padding-bottom: 1rem;
    }
    &3 {
      padding-bottom: 3rem;
    }
    &4 {
      padding-bottom: 4rem;
    }
    &8 {
      padding-bottom: 8rem;
    }
  }
  &r {
    &1 {
      padding-right: 1rem;
    }
  }
}

.m {
  &l-auto {
    margin-left: auto;
  }
  &-auto {
    margin: 0 auto;
  }
  &-right {
    margin-right: 0 !important;
  }
  &b {
    &1 {
      margin-bottom: 1rem;
    }
    &2 {
      margin-bottom: 2rem;
    }
    &24 {
      margin-bottom: 2.4rem;
    }
    &3 {
      margin-bottom: 3rem;
    }
    &4 {
      margin-bottom: 4rem;
    }
    &5 {
      margin-bottom: 5rem;
    }
    &6 {
      margin-bottom: 6rem;
    }
    &7 {
      margin-bottom: 7rem;
    }
    &10 {
      margin-bottom: 10rem;
    }
  }
  &t {
    &1 {
      margin-top: 1rem;
    }
    &2 {
      margin-top: 2rem;
    }
    &3 {
      margin-top: 3rem;
    }
    &4 {
      margin-top: 4rem;
    }
    &5 {
      margin-top: 5rem;
    }
    &6 {
      margin-top: 6rem;
    }
    &7 {
      margin-top: 7rem;
    }
    &8 {
      margin-top: 8rem;
    }
  }
  &r {
    &2 {
      margin-right: 2rem;
    }
    &1 {
      margin-right: 1rem;
    }
  }
  &y {
    &2 {
      margin: 2rem 0;
    }
    &3 {
      margin: 3rem 0;
    }
    &7 {
      margin: 7rem 0;
    }
  }
}
.float {
  &-right {
    float: right;
  }
}
.f {
  &16 {
    font-size: 1.6rem;
  }
}
.font {
  &-italic {
    font-style: italic;
  }
}

.inline-block {
  display: inline-block;
}

header {
  top: 0;
  position: sticky;
  z-index: 2;
  background: var(--dark);
  right: 0;
  // @include respond-from(sm){
  //  background: transparent;

  // }
}

.red-border {
  border: 1px solid red !important;
  border-radius: 10px;
}

.pointer {
  cursor: pointer !important;
}

.text-capital {
  text-transform: capitalize !important;
}

.text-upper {
  text-transform: uppercase !important;
}

@include respond-from(sm) {
  .order {
    &-2 {
      order: 2;
    }
  }
}

.login-signup-btn {
  position: relative;
  .loader {
    position: relative;
    height: 19px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .lds-roller {
    transform: translate(-50%, -50%) scale(-0.35);
    top: 90%;
    left: 50%;
    margin: 0;
    width: 50px;
    height: 20px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }

  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--theme);
    margin: -3px 0 0 -3px;
  }

  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }

  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
}

.loader {
  top: 35px;
  left: 37%;
  height: 30%;
  position: absolute;
}

.lds-roller {
  display: inline-block;
  position: absolute;
  width: 5rem;
  height: 2rem;
  transform: scale(-0.7);
  margin: -1.5rem 0;
  text-align: center;
  top: 50%;
  left: 50%;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 3.2rem 3.2rem;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: var(--white);
  margin: -0.3rem 0 0 -0.3rem;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 5rem;
  left: 5rem;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 5.4rem;
  left: 4.5rem;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 5.7rem;
  left: 3.9rem;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 5.8rem;
  left: 3.2rem;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 5.7rem;
  left: 2.5rem;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 5.4rem;
  left: 1.9rem;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 5rem;
  left: 1.4rem;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 4.5rem;
  left: 1rem;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.desk-none {
  display: none;
  @include respond-from(sm) {
    display: block;
  }
}
.mob-none {
  display: block;
  @include respond-from(sm) {
    display: none;
  }
}

.liveExam_slider {
  & .slick-list {
    // padding:0 20% 0 0 !important;
    // display: initial  !important;
  }
}
p.errorMsg {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.6;
}
.mob-border {
  // @include respond-from(sm){
  //   border-right: 12px solid #F05A27;
  // }
  footer {
    @include respond-from(sm) {
      display: none;
    }
  }
}
.custom {
  &-btn {
    &-l {
      position: absolute;
      top: 50%;
      z-index: 1;
      background: none;
      border: none;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      transform: rotate(90deg);
      left: -25px;
      font-weight: 600;
      @include respond-from(sm) {
        display: none;
      }
    }
    &-r {
      position: absolute;
      top: 50%;
      z-index: 1;
      background: none;
      border: none;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      transform: rotate(90deg);
      right: -25px;
      font-weight: 600;
      @include respond-from(sm) {
        display: none;
      }
    }
  }
}

.display {
  &-none {
    display: none !important;
  }
}

.main-loader {
  height: 100vh;
  width: 100vw;
  background: #000;
  .lds-roller {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.default {
  &-anchor {
    text-decoration: none;
  }
}
.mob-display-none {
  @include respond-from(sm) {
    display: none;
  }
}
.overflow-hidden {
  overflow: hidden;
  @include respond-from(sm) {
    overflow: initial;
  }
}

// .errpage{
//   position: relative;

// .errMsg {
//   position: absolute;
//   top: -4px;
//   }
// }

.main.transparent-header {
  header {
    background: var(--dark);
  }
}
hr {
  border-bottom: 0.5px solid var(--white1);
  border-top: 0;
}
.product {
  &-active {
    opacity: 1;
     &-border{
      border: 2px solid var(--white1);
      border-radius: 5px;
      box-shadow: 0px 32px 40px  hsla(0,0%,7%,.2);
    }
  }
  &-inactive {
    opacity: 0.5;
  }
}

#snackbar {
  // visibility: hidden;
  min-width: 27.4rem;
  background-color: #292a2a;
  color: #fff;
  text-align: center;
  border-radius: 0.5rem;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 3rem;
  font-size: 1.7rem;
  transform: translateX(-50%);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin-left: 1rem;
  }
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/////////////////////////////////////////////
.errpage {
  font-size: 1.4rem;
  padding: 0 0 1.5rem 3rem;
}

.successMsg {
  color: #00ff00;
  font-size: 1.4rem;
}

.errMsg {
  color: #ff0000;
  font-size: 1.4rem;
}

.z-index-1 {
  z-index: -1;
}

.not {
  &-tooltip {
    // position: relative;
    display: flex;
    border-bottom: 1px dotted black;
    cursor: pointer;
    h4 {
      text-transform: uppercase;
    }
    &:hover .not-tooltip-text {
      visibility: visible;
    }
    &-border {
      border-top: 1px solid var(--white) !important;
    }
    &-text {
      visibility: hidden;
      width: 100%;
      background-color: #202226;
      color: #fff;
      border-radius: 6px;
      padding: 1rem;
      position: absolute;
      z-index: 1;
      top: 34px;
      left: 50%;
      line-height: 1.6;
      font-size: 1.4rem;
      text-transform: capitalize;
      transform: translate(-50%);
      box-shadow: -2px 10px 12px 1px rgba(0, 0, 0, 0.5);
      transform: translate(-50%);
      > div {
        opacity: 0.5;
      }
      @include respond-from(sm) {
        left: unset;
        right: 0;
        transform: none;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 100%;
      //   left: 50%;
      //   margin-left: -5px;
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color: black transparent transparent transparent;
      // }
      
    }
  }
}

.height{
  &-100{
    height: 100%;
  }
}

.paypal-buttons{
  position: unset !important;
  & iframe{
    position: unset !important;
  }
}

.cursor{
  &-pointer{
    cursor: pointer;
  }
}
.slick-prev {
  left: -7px;
  z-index: 1;
}
.slick-next {
  right: -7px;
  z-index: 1;
}
.mb0{
  margin-bottom: 0 !important;
}

.f{
  &-14{
    font-size: 1.4rem;
  }
}


.text-color{
  &-default{
    color: #FFFFFF;
  }
}
.text-transform-none{
  text-transform: none !important;
}
// .mailchimp{
//   &-container{
//     justify-content: space-between;
//     width: 100%;

//     & > div{
//       width: 70%
//     }

//     & input{
//       border: none;
//       height: 100%;
//       background-color: var(--dark);
//       width: 90%;
//       color: rgba(255, 255, 255, 0.5);
//       border: 1px solid #fff;
//       padding: 1rem 1rem;

//       @include respond-from(md-xl){
//         width: 100%
//       }
//     }

//     & button{
//       border: none;
//       background: none;

//       margin-right: -10px;
//     }
//   }
// }
.modal{
  width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    min-height: 100vh;
    height: 100vh;
    display: flex;
    &-content{
      position: relative;
      background: #fff;
      padding: 5rem;
      box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      z-index: 100;
      max-width: 50rem;
      overflow: auto;
      margin: auto;
     
      p{
        font-size: 2rem;
        line-height: 1.6;
        margin-bottom: 2rem;
      }
    }
    &-cross{
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
    }
    button{
      font-size: 1.6rem;
      background: var(--dark);
      border: none;
      color: #fff;
      padding: 1.2rem 1.5rem;
      border-radius: 6px;
    }
    strong{
      font-weight: 600;
    }
}