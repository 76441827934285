button {
  cursor: pointer !important;

  &:disabled {
    cursor: not-allowed !important;
  }
}

.btn {
  background: none;
  border: none;

  &-apply-code{
    color: var(--white);
    text-transform: uppercase;
  }

  &-plus-minus {
    font-size: 3.2rem;
    color: var(--white);
  }
  &-save {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--dark);
    border: 1px solid var(--dark);
    padding: 1rem 2rem;
    border-radius: 8px;
    background: transparent;
  }
  &-add {
    padding: 1.4rem 2rem;
    border: 0.5px solid #ffffff;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #ffffff;
    @include respond-from(sm) {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 12px;
    }
    img {
      padding-right: 0.8rem;
      @include respond-from(sm) {
        padding-right: 12px;
      }
    }
  }
}
