.account {
  background: url(../../assets/accountbg.png) no-repeat;
  background-size: cover;
  padding-top: 4.5rem;
  display: inline-block;
  width: 100%;
  &-orders {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--dark);
    &-inner {
      display: inline-block;
      width: 100%;
      margin-bottom: 2rem;
    }
    a {
      border: 1px solid var(--dark);
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--dark);
      padding: 0.5rem 2rem;
      background: none;
      margin-top: 1rem;
    }
  }
  &-table {
    overflow: auto;
    table {
      width: 100%;
      border-collapse: collapse;
    }
  }
  &-card {
    opacity: 0.5;
    font-weight: 600;
    padding-top: 1rem;
  }
  &-address {
    max-width: 55.6rem;
  }
  &-logout {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--dark);
    &-text {
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--dark);
        opacity: 0.5;
        padding-right: 2rem;
      }
      button {
        border: 1px solid var(--dark);
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--dark);
        padding: 0.5rem 2rem;
        background: none;
      }
    }
  }
  &-btns {
    position: absolute;
    bottom: 0;
    right: 0;
    @include respond-from(sm) {
      position: relative;
      bottom: 0;
      right: 0;
      display: flex;
    }
  }
  &-bookmark {
    &-text {
      font-size: 1.2rem !important;
    }
    &-btn {
      border: 0.333977px solid var(--dark);
      border-radius: 0.5rem;
      padding: 1rem 1.6rem;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 15px;
      text-transform: uppercase;
      color: var(--dark);
      @include respond-from(sm) {
        flex: none;
      }
    }

    & img{
      min-width: 24%;
      max-width: 24%;
    }
  }
  &-payment {
    border-right: 0.2rem solid rgba(0, 0, 0, 0.5);
    height: 100%;
    // padding-right: 1rem;
    min-width: 16rem;
    &-input {
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
      font-weight: 600;
      padding: 1rem;
      border: 1px solid var(--dark);
      margin-top: 0.8rem;
      // margin-left: 1rem;
    }
    li {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
      opacity: 0.5;
      &:not(:last-child) {
        padding-bottom: 2rem;
      }
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
    &-row {
      & > div {
        @include respond-from(sm) {
          margin-bottom: 10px;
        }
      }
    }
    &-right {
      max-width: 50rem;

      & label {
        // display: flex;
        width: auto !important;

        // align-items: center;
        & > div {
          width: 50rem;
          padding-left: 1rem;
          max-width: 100%;
        }
      }
    }
  }
  // &-right {
  //   padding-left: 3rem;
  // }

  &-news {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--dark);
    input {
      display: none !important;
      &:checked + label span::before {
        display: block !important;
      }
    }
    span {
      border: 0.5px solid var(--dark);
      border-radius: 1px;
      max-width: 16px !important;
      min-width: 16px !important;
      height: 16px;
      display: inline-block;
      position: relative;
      margin-right: 1rem;
      cursor: pointer;
      &:before {
        content: "";
        background: var(--dark);
        border-radius: 8px;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }
    }
  }
  &-left {
    flex-basis: 20.5rem;
    @include respond-from(sm) {
      flex-basis: 100%;
    }
    ul {
      border-right: 0.2rem solid rgba(0, 0, 0, 0.5);
      height: 100%;
      li {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--dark);

        &:not(:last-child) {
          margin-bottom: 2.4rem;
        }

        img {
          margin-right: 1rem;
        }
      }
    }
  }
  &-right {
    flex: 1;
    padding-left: 3rem;
    @include respond-from(sm) {
      width: 100%;
      padding: 20px 0;
      flex: none;
    }
    &-adress {
      label {
        width: 27.3rem !important;
      }
    }
    &-head {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 19px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
    }
    &-inner {
      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--dark);
        width: 15.7rem;
        padding: 1rem 0;
        @include respond-from(sm) {
          width: auto;
          min-width: 15.7rem;
        }
      }
      input {
        border: none;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--dark);
        opacity: 0.5;
        background: none;
        font-weight: 600;
        width: 28rem;
        padding: 1rem;
        @include respond-from(sm) {
          width: auto;
          min-width: 28rem;
        }
        &::placeholder {
          color: var(--dark);
          opacity: 0.5;
        }
      }
    }
  }
  h3 {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 1;
    letter-spacing: 0.08em;
    color: var(--dark);
    button {
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      padding-left: 3rem;
      background: none;
      border: none;
    }
  }
  table {
    th {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 19px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
      text-align: left;
      position: relative;
      padding: 10px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
    }
    td {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      opacity: 0.5;
      color: var(--dark);
      position: static;
      padding: 10px;
    }
    tr {
      width: 100%;
      vertical-align: top;
      padding: 10px;
      position: relative;
      td,
      th {
        //   width: 14.2rem;
      }
    }
  }
  &-container {
    max-width: 114rem;
    margin: 0 auto;
    width: 94%;
    position: relative;

    h2 {
      font-weight: 600;
      font-size: 4rem;
      line-height: 1.6;
      display: flex;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
      flex-wrap: wrap;
      align-items: center;
      span {
        font-size: 2.4rem;
      }
    }
  }

  hr {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-color: rgba(0, 0, 0, 0.25);
  }
}

.input-border {
  input {
    border: 1px solid var(--dark);
  }
}
.btn-abs {
  @include respond-from(sm) {
    position: absolute;
    left: 0;
    bottom: 33px;
    padding: 0;
  }
}
