.bill{
    padding: 80px 0;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    h4{
        font-size: 18px;
       margin-bottom: 10px;
    }
    p{
        line-height: 1.6;
        max-width: 300px;
        a{
            color: #000000;
        }
    }
    &-main{
        overflow: auto;

    }
    &-left{
        min-width: 500px;
    }
    &-right{
        min-width: 300px;
    }
    &-head{
        display: flex;
        align-items: center;

        h3{
            font-size: 24px;
        }
        li{
            border: 1px solid #000000 ;
            padding: 5px 10px ;
            border-radius: 20px;
            display: flex;
            align-items: center;
            margin: 0 5px;
            span{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #000000;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
    &-subhead{
        font-size: 20px;

    }
    &-order{
        
        img{
            border: 1px solid #000000;
            border-radius: 5px;
            width: 60px;
        }
    }
    table{
        max-width: 750px;
        width: 100%;
        border-collapse: collapse;
       
        th{
            font-size: 18px;
            font-weight: 600;
            text-align: left;
        }
        td{
            padding: 10px 0;
        }
        tfoot{
            tr{
            border-top: 1px solid #000000;
            border-bottom: 1px solid #000000;
            }
        }
    }
}