.checkout {
  .main {
    background: url(../../assets/accountbg.png) no-repeat;
    background-size: cover;
  }
  &-payment {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    background: none;
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  &-head {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 29px;
    letter-spacing: 0.08em;
    color: #000000;
  }
  &-form {
    .header-order {
      position: static;
      border-width: 5px;
      height: 100vh;
      max-width: 38rem;
      float: right;
      @include respond-from(sm) {
        display: none;
      }
    }
    // .cart-right {
    //   margin-top: 20rem;
    // }
    label {
      font-weight: bold;
      font-size: 1.4rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #191a1c;
      width: 100%;
      margin-bottom: 1rem;
      display: inline-block;
      @include respond-from(sm) {
        font-size: 12px;
      }
    }
    input,
    select {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px;
      background: none;
      width: 100%;
      font-size: 1.2rem;
      @include respond-from(sm) {
        font-size: 12px;
      }
    }
  }
  &-main {
    display: inline-block;
    width: 100%;
    .col-xl-6 {
      margin-bottom: 2.4rem;
    }
    .listing {
      label {
        display: flex;
      }
    }
    p {
      font-style: italic;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.09em;
      color: #000000;
      @include respond-from(sm) {
        font-size: 12px;
        line-height: 15px;
      }
    }

    & .errMsg {
      color: #ff0000;
      font-size: 1.4rem;
      margin-top: 1rem;
    }

    .listing {
      margin: 0;
    }
  }

  &-nav {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #191a1c;
    margin-bottom: 6rem;
    span {
      padding: 0 1rem;
    }
  }

  &-btn {
    background: #191a1c;
    border: 0.333977px solid #191a1c;
    border-radius: 5.34363px;
    color: #e4d9c5;
    padding: 1rem 8rem;
    float: right;
    font-weight: 600;
    font-size: 12.0232px;
    @include respond-from(sm) {
      float: none;
      display: flex;
      justify-content: center;
      margin: 2rem auto 5rem;
      width: 200px;
      padding: 10px 16px;
    }
  }

  &-list {
    & .listing {
      li {
        color: var(--dark) !important;
      }

      // label{
      //   color: var(--dark);
      // }

      span {
        border-color: var(--dark) !important;

        &::before {
          background: var(--dark) !important;
        }

        @include respond-from(xs_small) {
          align-self: baseline;
          top: 6px;
        }
      }
    }
  }

  &-right {
    padding-right: 0px;
  }
  &-loader{
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    font-size: 1.6rem;
    position: relative;
    text-align: center;
    .loader{
      position: static;
      height: 8rem;
    }
    .lds-roller{
      position: static;
      margin: 0;
      height: 6rem;
      div:after {
        background: var(--theme);
    }
    }
  }
}
.thanks-heading {
  font-size: 20px;
  margin-bottom: 2rem;
  color: #191a1c;
}

.thanks {
  width: 60%;
  max-width: 60%;
  margin: auto;
  text-align: center;

@include respond-from(sm){
  max-width: unset;
  width: 100%;
}
  p {
    font-size: 16px;
    text-align: center;
  }
  ul {
    font-size: 14px;
    li {
      padding-bottom: 2rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        padding-left: 1rem;
      }
    }
  }

  & h3{
    text-align: left;
    font-size: 2rem;
    font-weight: 600;
  }

  &-price-summary {
    border: 1px solid var(--dark);
    border-radius: 10px;
    width: 100%;

    padding: 2rem;
  }
}

.float-none {
  float: none !important;
}
.terms {
  display: inline-block;
  width: 100%;
  color: #fff;
  h4 {
    text-align: center;
    margin: 20px 0 40px;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  &-container {
    margin: 0 auto;
    float: none;
  }
  &-para {
    color: #fff;
    text-align: justify;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 50px;
  }
  a {
    color: #fff;
    margin: 0 1rem;
  }
}
.checkout-margin {
  margin: 0 -1rem;
}
.checkout .breadcrumb img {
  filter: brightness(0);
}
.checkout-price {
  width: 20rem;
  text-align: left;
  @include respond-from(sm){
    width: 25rem;
  }
}
.checkout-address {
  @include respond-from(sm){
  width: 300px;
  }
}