.personalised {
  // background: url(../../assets/personalised_banner.svg) no-repeat;
  height: 412px;
  position: relative;
  background-size: cover !important;
  margin-top: -144px;
  @include respond-from(sm) {
    height: 194px;
    margin-top: -69px;
  }
  &-section {
    padding-top: 5rem;
    &-inner {
      width: 33.33%;
      padding: 1rem;
      float: left;
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      @include respond-from(sm) {
        width: 50%;
      }
      img {
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.03);

          box-shadow: 9px 3px 14px 8px rgba(0, 0, 0, 0.4);
        }
      }
      span {
        transform: translate(-50%, -50%);
        font-weight: 600;
        text-align: center;
        font-size: 4.8rem;
        line-height: 5.8rem;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        @include respond-from(sm) {
          font-size: 24px;
        }
      }
      img {
        width: 100%;
        border-radius: 4px;
        height: 400px;
        object-fit: cover;
        @include respond-from(sm) {
          height: 176px;
        }
      }
      h3 {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        padding: 1rem 0 3rem;
        color: var(--white);
        @include respond-from(sm) {
          font-size: 13px;
        }
      }
    }
    &-change {
      margin: 0 -1rem;
      & > button {
        &:nth-child(4),
        &:nth-child(5) {
          width: 50%;
        }
      }
      h4 {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.9rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: #e7dcc8;
        width: 100%;
        border-bottom: 0.5px solid #e7dcc8;
      }
    }
  }
}

.listing {
  margin: 3rem 0;
  label {
    display: flex;
    align-items: center;
  }
  input {
    display: none;

    &:checked + label span {
      display: block;
      &:before {
        content: "";
        background: var(--white);
        border-radius: 8px;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  span {
    border: 0.5px solid var(--white1);
    border-radius: 1px;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    margin-right: 1rem;
    cursor: pointer;
    top: -2px;
    flex:none;
  }
  &-veg {
  }
  &-head {
    padding: 0.5rem 0;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    border-bottom: 0.5px solid var(--white1);
    margin-bottom: 2rem;
    width: 100%;
  }
  ul {
    padding-right: 2rem;
  }

  li {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 191.8%;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    display: flex;
    align-items: center;
    @include respond-from(sm){
    &:not(:last-child){
      padding-right: 1.4rem;
    }
  }
  }
}

.hot {
  background: url(../../assets/hotbanner.png) no-repeat;
  height: 412px;
  position: relative;
  margin-top: -148px;
      background-size: cover;
  @include respond-from(sm) {
    height: 400px;
    position: relative;
    margin-top: -69px;
  }
  &-listing {
    max-width: 448px;
    ul {
      column-count: 3;
    }
  }
}
.customise-listing {
  .listing {
    margin-top: 0;
  }
  .listing-head {
    padding: 0.5rem 0;
  }
}
