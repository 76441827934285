.login {
  display: inline-block;
  width: 100%;
  padding-bottom: 4rem;
  &-head {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 5.8rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    text-align: center;
    @include respond-from(sm){
      font-size: 18px;
    }
  }
  &-left {
    max-width: 550px;
    margin: 0 auto;
    @include respond-from(sm){
      display: none;
    }
  }
  &-right {
    max-width: 500px;
    margin: 0 auto;
    @include respond-from(sm){
      margin-top: 20px;
    }
    img {
      width: 2rem;
      margin-right: 1rem;
      @include respond-from(sm){}
      width: 20px;
      margin-right: 10px;
    }
    &-btn {
      padding-left: 3rem;
    }
    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--white);
      border: 0.5px solid var(--white1);
      border-radius: 4px;
      background: none;
      width: 50%;
      height: 5rem;
      padding: 1rem;
      &:first-child {
        margin-right: 2rem;
      }
      @include respond-from(sm){
        height: 35px;
        font-size: 13px;
      }
      &.orange {
        color: var(--theme);
        border-color: var(--theme);
      }
    }
  }
  &-input {
    label{
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      letter-spacing: 0.08em;
      color: var(--white);
      margin-bottom: .5rem;
      display: inline-block;
    }
    input {
      border: 0.5px solid var(--white1);
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.08em;
      color: var(--white);
      opacity: 0.5;
      background: none;
      width: 100%;
      padding: 1rem;
      min-height: 4rem;
      &::placeholder {
        color: var(--white);
        opacity: 0.5;
      }
    }
  }
  
  &-agree {
    font-weight: 600;
    font-size: 14px;
    line-height: 191.8%;
    display: flex;
    align-items: center;
    letter-spacing: 0.09em;
    // text-decoration-line: line-through;
    text-transform: uppercase;
    padding-left: 3rem;
    color: var(--white);
    @include respond-from(sm){
      justify-content: center;
    }
    a{
      font-weight: 600;
    font-size: 14px;
    line-height: 191.8%;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    }
    input {
      display: none;
      &:checked + label span::before{
        display: block;
      }
    }
    span {
      cursor: pointer;
      position: relative;
      margin-right: 1rem;
      border: 0.5px solid var(--white1);
      border-radius: 1px;
      width: 16px;
      height: 16px;
      border-radius: 1px;
      display: inline-block;
    vertical-align: text-top;
      &:before {
        content: "";
        background:var(--white);
        border-radius: 8px;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: none;
      }
    }
  }
  &-forget {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    text-align: right;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: var(--white);
    a {
      color: var(--white);
      text-decoration: none;
    }
  }
  &-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    color: var(--white);
    margin-bottom: 2rem;
  }
}
.register {
  margin: 0 -1rem 2rem;
  &-inner {
    width: 50%;
    padding: 0 1rem;
  }
  &-btn {
    @include respond-from(sm){
      text-align: center;
    }
    button {
      margin-right: 0 !important;
    }
  }
}
