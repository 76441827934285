.home-banner {
  // height: 90vh;
  // // width: 100vw;
  // position: relative;
  &-main {
    position: relative;
    @include respond-from(sm) {
      margin-top: -69px;
    }
  }

  &__video {
    height: calc(100vh - 13.5rem);
    min-height: calc(100vh - 13.5rem);
    width: 100%;
    position: relative;

    @include respond-from(sm) {
      height: 600px;
      min-height: 600px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    & .video {
      // height: 100%;
      // width: 100%;
    }
  }

  &__audio {
    z-index: 1;
    position: absolute;
    top: 0;
    padding: 2rem 0;
    margin: 0 -1rem;
    display: flex;
    justify-content: space-around;
    transform: translateX(-50%);
    left: 50%;
    flex-wrap: wrap;
    margin: unset;

    @include respond-from(sm) {
      top: unset;
      bottom: 0;
    }

    & .box {
      padding: 1rem;
    }

    & .box-1 {
      border: 0.1rem solid #e7dcc8;
      box-sizing: border-box;
      padding: 1rem;
      //width: 45%;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #e7dcc8;
      @include respond-from(sm) {
        font-size: 14px;
      }
      span {
        background: var(--theme);
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}

.home {
  &-end {
    align-self: center;
    padding-bottom: 2rem;
  }

  &-hot {
    background: #e7dcc8;
    padding: 5rem 0;

    // &-content{
    //   p{
    //     font-size: 1.2rem;
    //     font-weight: 600;
    //     line-height: 19.2px
    //   }
    // }

    &-inner {
      padding-bottom: 2rem;
    }
    &-remove {
      border-color: var(--theme);
      background: var(--theme);
      color: #fff;
    }

    &-btn {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--dark);
      border: 0.1rem solid var(--dark);
      border-radius: 0.8rem;
      padding: 2rem;
      background: none;
      margin: 4rem auto 0;
      display: flex;

      @include respond-from(sm) {
        display: none;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.8rem;
      text-align: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
      @include respond-from(sm) {
        font-size: 24px;
      }
    }

    & p{
      font-size: 2rem;
      line-height: 1.92rem;
      color: var(--dark);
      max-width: 61.9rem;
      margin: 0 auto;
      font-weight: 400;
      min-height: 5.759rem;
      text-align: center;

      @include respond-from(sm) {
        font-size: 12px;
        font-weight: 600 !important;
        line-height: 19.2px;
        max-width: 275px;
        padding-top: 10px;
      }
    }

    &-inner {
      button {
        border: 0.05rem solid var(--dark);
        border-radius: 0.8rem;
        background: none;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: var(--dark);
        padding: 1.5rem;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          padding-right: 0.5rem;
        }

        @include respond-from(sm) {
          font-size: 12.0232px;
          line-height: 15px;
          padding: 10px 16px;
          border-radius: 5.34363px;
          justify-content: center;
        }

        img {
          @include respond-from(sm) {
            display: none;
          }
        }
      }
    }

    &-head {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--dark);
      border-bottom: 0.1rem solid var(--dark);
      padding: 5px 0;
      min-height: 7rem;
      @include line-clamp(2);
      @include respond-from(sm) {
        font-size: 16px;
      }
    }

    &-content {
      & p{
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 160%;
        letter-spacing: 0.08em;
        text-transform: capitalize;
        color: var(--dark);
        padding: 1rem 0 0;
        text-align: left;
        //max-height: 4.2em;
        @include line-clamp(3);
        @include respond-from(sm) {
          font-size: 7.24378px;
          line-height: 9px;
          letter-spacing: 0.08em;
        }
      }
    }

    &-section {
      padding: 3rem 0;
      // overflow: hidden;
    }
  }

  &-happy {
    background: #292a2a;
    display: inline-block;
    width: 100%;

    &-inner {
      max-width: 140rem;
      margin: 0 auto;
    }

    &-today {
      & > div {
        padding-bottom: 2rem;
      }
    }

    &-container {
      margin-right: 0;
      width: 95%;
    }

    h3 {
      padding: 4rem 0;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.8rem;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--white);

      @include respond-from(sm) {
        max-width: 285px;
        margin: 0 auto;
        font-size: 24px;
        line-height: 29px;
      }
    }

    .scoller {
      align-items: center;
      // animation: scroll 20s linear infinite;
      flex-direction: row;

      .active {
        color: #f05a27;
      }
      &-main {
        //  align-items: center;
        display: inline-block;
        // display: flex;
        // flex-direction: row;
        overflow-x: hidden;
        position: relative;
        width: 100%;

        &:hover {
          .scoller {
            a {
              animation-play-state: paused;
            }
          }
        }
      }

      a {
        font-size: 1.8rem;
        line-height: 2.2rem;
        text-decoration: none;
        color: var(--white);
        border: 0.05rem solid var(--white);
        padding: 1.7rem 2rem;
        border-radius: 1.6rem;
        margin-right: 10px;
        flex: none;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        animation-name: marquee;
        animation-iteration-count: infinite;
        animation-duration: 6s;
        animation-timing-function: linear;
        min-width: 20rem;
        max-width: 100%;

        @include respond-from(sm) {
          font-size: 14px;
          border-radius: 12px;
          padding: 10px 20px;
        }

        img {
          padding-right: 2.5rem;
          min-width: 2.6rem;

          @include respond-from(sm) {
            padding-right: 10px;
          }
        }
      }
    }
  }

  &-marquee {
    background: var(--theme);
    padding: 16px 0;
    overflow: hidden;

    @include respond-from(sm) {
      position: absolute;
      top: 69px;
      left: 0;
      width: 100%;
      padding: 10px 0;
    }

    li {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #000000;
      white-space: nowrap;
      display: inline-block;
      animation-name: marquee;
      animation-iteration-count: infinite;
      animation-duration: 6s;
      animation-timing-function: linear;
      flex: none;
      max-width: 65rem;
      width: 100%;
      @include respond-from(sm) {
        width: auto;
        padding: 0 2rem;
      }
    }
  }

  &-seller {
    background: rgba(44, 44, 46, 0.7);
    padding: 7rem 0;
    color: var(--lightgrey);

    &-content {
      max-width: 62rem;
      margin: 0 auto;

      @include respond-from(sm) {
        max-width: 275px;
      }
    }

    &-head {
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.8rem;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      padding-bottom: 1rem;
      @include respond-from(sm) {
        font-size: 24px;
      }
    }

    &-subhead {
      font-size: 16px;
      line-height: 19px;

      @include respond-from(sm) {
        font-size: 12px;
      }
    }
  }

  &-two {
    padding: 2rem 0;
    width: 100%;

    // display: inline-block;
    &-right {
      max-width: 45rem;
      padding: 0 0 0rem 10rem;

      @include respond-from(sm) {
        padding: 0;
      }

      p {
        text-align: left !important;
      }
    }

    &-main {
      background: url("../../assets/Truffle.svg") no-repeat right top/400px;
      background-attachment: fixed;
    }

    &-main1 {
      background: url("../../assets/Chilli.svg") no-repeat left top/400px;
      background-attachment: fixed;
    }

    &-left {
      width: 21.6rem;
      margin: 0 auto;
      padding-top: 14rem;

      @include respond-from(sm) {
        margin-right: 0;
        padding: 0;
        width: 216px;
      }
    }

    p {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: right;
      letter-spacing: 0.08em;
      color: var(--white);
      @include respond-from(sm) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-head {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);
      border-bottom: 0.05rem solid var(--white);
      margin-bottom: 1rem;

      @include respond-from(sm) {
        font-size: 24px;
        line-height: 29px;
        margin-top: 2rem;
      }
    }

    &-img {
      img {
        position: relative;
        margin-top: -13rem;
        margin-left: 4rem;
        z-index: 0;
        max-width: 50rem;
        width: 100%;
        border-radius: 4px;
        @include respond-from(sm) {
          max-width: 271px;
          margin: 20px 0;
          float: right;
        }
      }
    }

    &-img1 {
      position: relative;
      z-index: 1;
      backface-visibility: hidden;
      img {
        // width: 100%;
        // margin-left: -4rem;
        width: 100%;
        margin-left: -4rem;
        max-width: 64rem;
        border-radius: 4px;
        @include respond-from(sm) {
          margin: 0;
        }
      }
    }
  }
}

.review {
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #e7dcc8;

  @include respond-from(sm) {
    font-size: 10.1782px;
    line-height: 140.62%;
  }
  &-slides {
    min-height: 250px;
    @include respond-from(sm) {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }

    p {
      @include line-clamp(4);
    }
  }
  &-star {
    justify-content: center;
    margin-top: 1rem;

    img {
      margin: 0 0.4rem;
      width: 2.5rem;
    }

    @include respond-from(sm) {
      justify-content: center;
    }

    img {
      @include respond-from(sm) {
        width: 15px;
      }
    }
  }

  // &-section {
  //   border-bottom: 0.5px solid #FFFFFF;
  // }

  h3 {
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 5.8rem;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    @include respond-from(sm) {
      font-size: 24px;
    }
  }

  &-google {
    padding: 1.7rem 0;
    color: #e7dcc8;
    // margin-bottom: 1rem !important;
    &-img {
      height: 250px;
      object-fit: cover;
    }
  }

  &-inner {
    float: none;
    max-width: 235px;
    margin: 0 auto;
    display: flex !important;
    flex-flow: column;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.home-banner {
  height: calc(100vh - 13.5rem);
  min-height: calc(100vh - 13.5rem);
  position: relative;
  // display: inline-block;
  width: 100%;
  overflow: hidden;

  @include respond-from(sm) {
    height: 100vh;
    max-height: 600px;
    min-height: 600px;
  }

  .slick-slide > *,
  .slick-slide > * > * > img {
    height: 100%;
    width: 100%;
  }

  .slick-track,
  .slick-list,
  .slick-slider,
  .slick-slide {
    height: 100%;
  }

  &-text {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
    width: 100%;

    h2 {
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.8rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #fff;
      @include respond-from(sm) {
        font-size: 24px;
      }
    }

    button {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
      background: none;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      border: 0.1rem solid #fff;
      border-radius: 0.8rem;
      padding: 2rem;
      color: #fff;
      margin-top: 2rem;

      @include respond-from(sm) {
        display: none;
      }
    }
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.home-page-carousel .slick-track {
  margin-left: 0;
}
.review-section-slide {
  .slick-track {
    display: flex;
    .slick-slide {
      &:nth-child(even) {
        .review-google-img {
          order: 1;
          margin-bottom: 5rem;
        }
        .review-slides {
          order: 2;
        }
      }
    }
  }
}
.home-happy-today img {
  height: 287px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  @include respond-from(xs_sm) {
    height: 180px;
  }
}
.flex-classes {
  flex: 1;
  img {
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
    @include respond-from(md) {
      max-height: 180px;
      min-height: 180px;
    }
  }
}

// .home-page-carousel {
//   display: flex;
// }
.margin-top-8 {
  @include respond-from(sm) {
    margin-top: 8px;
  }
}
