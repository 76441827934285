.truffle {
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  &-stepes {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  &-subhead {
    font-weight: 600;
    font-size: 4rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    border-bottom: 0.5px solid var(--white1);
    padding-bottom: 1rem;
  }
  &-step {
    margin: 6rem 0 4rem;
    width: 100%;
    justify-content: space-between;
    position: relative;
    display: inline-block;
    @include respond-from(sm) {
      margin-bottom: 32px;
    }

    &-orange {
      background: #ff5000 !important;
      // li{
      // background:#FF5000 !important;
      & span {
        background: #ff5000 !important;
      }
      // }
    }

    ul {
      width: 100%;
      justify-content: space-around;
      position: relative;
      li {
        width: 33.33%;
        display: flex;
        justify-content: center;
        height: 0.07rem;
        background: #fff;
        top: 50%;
        z-index: 0;
        align-items: center;
      }
    }
    &-orange {
      //         left: 0;
      // position: absolute;
      // width: 62%;
      // height: .07rem;
      // background: #FF5000;
      // top: 50%;
      // z-index: 1;
    }
    &-line {
      position: absolute;
      width: 100%;
      height: 0.07rem;
      background: #fff;
      top: 50%;
      z-index: 0;
    }
    &-inner {
      position: relative;
      justify-content: space-around;
      width: 100%;
    }
    span {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.09em;
      color: #fff;
      background: var(--dark);
      border: 0.1rem solid var(--white);
      border-radius: 50%;
      width: 2rem;
      position: relative;
      z-index: 1;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-from(sm) {
        width: 20px;
        font-size: 12px;
        height: 20px;
        line-height: unset;
      }
    }
  }
  &-section {
    &-one {
      padding-top: 5rem;
    }
    &-two {
      margin-top: -12rem;
      img {
        width: 56.4rem;
        height: 72.4rem;
      }
      &-inner {
        max-width: 56.4rem;
      }
    }
    &-three {
      padding-top: 5rem;
      padding-left: 5rem;
    }
  }
  &-padding {
    padding: 7rem 0;
  }
  &-content {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.08em;
    color: var(--white);
    text-align: right;
    &-four {
      padding-top: 5rem;
      &-inner {
        padding-top: 5rem;
      }
    }
    &-left {
      max-width: 21.6rem;
      float: left;
      margin-top: 3rem;
      text-align: left;
      .truffle-content-head {
        text-align: left;
      }
    }
    &-change {
      max-width: 21.6rem;
      float: right;
      margin-top: 3rem;
    }
    &-main {
      max-width: 33.7rem;
      float: right;
    }
    &-head {
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: right;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      border-bottom: 0.05rem solid var(--white1);
      color: var(--white);
    }
  }
  &-head {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    @include respond-from(sm) {
      font-size: 14px;
    }
  }
  &-next {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.09em;
    color: var(--white);
    justify-content: end;
    // padding-top: 5rem;
    @include respond-from(sm) {
      padding-top: 0;
      font-size: 12px;
    }
    img {
      margin-left: 1rem;
      max-width: 1rem;
      @include respond-from(sm) {
        width: 10px;
      }
    }
  }
  &-listing {
    .listing {
      padding: 0;
    }
    ul {
      display: flex !important;
      flex-wrap: wrap;
      max-height: 25rem;
      justify-content: inherit;
      column-gap: 1.5rem;
      row-gap: 0.5rem;
      @include respond-from(sm) {
        flex-flow: unset;
        flex-wrap: wrap;
      }
      li {
        // @include respond-from(sm){
        //     width: 50%;
        // }
      }
    }
  }
  &-inner {
    &-head {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);
      border-bottom: 0.5px solid var(--white1);
      margin-bottom: 1rem;
      padding: 0.5rem 0;

      @include respond-from(sm) {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;
      }
      span {
        font-size: 2.4rem;
        display: inline-block;
        padding-left: 0.5rem;
      }
    }
    &-content {
      p {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        letter-spacing: 0.08em;
        color: var(--white);
        margin-bottom: 2rem;
        @include respond-from(sm) {
          font-size: 14px;
          line-height: 160%;
        }
      }
      span {
        font-style: italic;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.5rem;
        letter-spacing: 0.09em;
        color: var(--white);
      }
    }
  }
  &-free {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    display: inline-block;
    width: 100%;
    @include respond-from(sm) {
      font-size: 11px;
      letter-spacing: 0.09em;
      margin-top: 25px;
    }
    &-inner {
      border: 0.05rem solid var(--white1);
      border-left: 0;
      border-right: 0;
      padding: 1rem 0;
      margin-bottom: 1rem;
    }
    img {
      width: 2rem;
      @include respond-from(sm) {
        width: 13px;
      }
    }
  }
}

.header-menu .header-search {
  display: none;
  @include respond-from(sm) {
    display: flex !important;
    padding: 0 0 20px !important;
  }
}
