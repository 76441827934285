.pearl {
  &-choco{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.08em;
    color: var(--white);
  }
  
  &-products {
    position: relative;
}

  &-steps {
    width: 42rem;
    margin:0 auto;
    @include respond-from(md){
      float: right;
    }
    @include respond-from(sm){
      overflow: hidden;
      
    //   float: none;
    //  margin:  0px 0 0 -126px !important;
      // margin-left: -126px;
      // width: auto;
    }
  }

  // &-circle{
  //   border: 3px solid salmon;
  //   border-radius: 10000000px;
  //   height: 200px;
  //   width: 200px;
  //   overflow: hidden;
  // }

  &-color {
    // position: absolute;
    // top: 0;
    // div {
    //   width: 4.5rem !important;
    //   position: absolute;
    //   img {
    //     width: 4.2rem;
    //     height: 4.2rem !important;
    //     object-fit: none;
    //     border-radius: 50%;
    //   }
    //   &:first-child {
    //     top: 1.2rem;
    //     right: 6.2rem;
    //   }
    //   &:nth-child(2) {
    //     top: 3.3rem;
    //     right: 2.5rem;
    //   }
    //   &:nth-child(3) {
    //     top: 7.3rem;
    //     right: 0.9rem;
    //   }
    //   &:nth-child(4) {
    //     top: 11.4rem;
    //     right: 1.5rem;
    //   }
    //   &:nth-child(5) {
    //     bottom: 2.3rem;
    //     right: 4rem;
    //   }
    //   &:nth-child(6) {
    //     bottom: 1rem;
    //     right: 8.4rem;
    //   }
    //   &:nth-child(7) {
    //     bottom: 2.3rem;
    //     left: 4.2rem;
    //   }
    //   &:nth-child(8) {
    //     top: 11.4rem;
    //     left: 1.5rem;
    //   }
    //   &:nth-child(9) {
    //     top: 7.3rem;
    //     left: 0.9rem;
    //   }
    //   &:nth-child(10) {
    //     top: 3.3rem;
    //     left: 2.5rem;
    //   }
    //   &:nth-child(11) {
    //     top: 1.2rem;
    //     left: 6.1rem;
    //   }
    //   &:nth-child(12) {
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //   }
    // }
    &-inner {
      position: relative;
      // width: 100%;
      width: 21rem !important;
      max-width: 100%;
      // @include respond-from(sm){
      //   min-width: 50% !important;
      //   width: auto;
      // }
    }
    &-top {
      width: 21rem !important;
      max-width: 100%;
      @include respond-from(sm){
        min-width: 50% !important;
        width: auto;
      }
    }
  }
  .truffle-next {
    padding: 0;
  }
  &-step {
    width: 90rem;
    max-width: 100%;
    padding: 0 2rem;
    &-inner {
      justify-content: space-around;
      width: 100%;
      position: absolute;
      top: -3rem;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.09em;
      color: var(--white);
      @include respond-from(sm){
        font-size: 8px;
      }
      li {
        &:not(&:last-child) {
          position: relative;
          left: -2rem;
          @include respond-from(xs){
            left: -1rem;
          }
        }
      }
    }
  }
  &-span {
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  &-price {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.8rem;
    text-align: right;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    &-left {
      width: 100%;
      img{
        padding: 0 .5rem;
      }
    }
    span {
      font-size: 1.2rem;
      display: inline-block;
      width: 100%;
    }
  }
  &-pitch {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0.09em;
    color: rgba(255, 255, 255, 0.7);
  }
  &-two {
    img {
      padding-right: 1.4rem;
    }
  }
  &-content {
    font-weight: 600;
    font-size: 1.4rem;
    //text-align: justify;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: var(--white);
    line-height: 1.6;
    border-bottom: 0.04rem solidvar(--white);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    display: inline-block;
  }
  &-list {
    padding: 0 1rem;
    &-head {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: var(--white);
      padding-right: 1rem;
    }
    li {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-decoration-line: line-through;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 0.7);
      padding-right: 1rem;
      &.green {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 160%;
        letter-spacing: 0.08em;
        text-transform: capitalize;
        color: #50c200;
        text-decoration: none;
      }
    }
  }
  &-three {
    display: inline-block;
    width: 100%;
    &-right {
      text-align: right;
    }
    &-slider {
      overflow: auto;
      &:hover {
        & > div{
        animation: none;
        }
      }
      // &::-webkit-scrollbar {
      //   width: 0;
      // }
      .slick-track{
        min-width: 100% ;
      }

      .addbutton {
        position: absolute;
        width: 1.8rem !important;
        top: 50%;
        right: 2rem;
        padding: 0;
        cursor: pointer;
        transform: translateY(-50%);
        @include respond-from(sm){
          right: 0;
        }
      
    }
    
      & > div{
        width: 100%;
       // position: relative;
        //  animation-name: marquee;
      // animation-iteration-count: infinite;
      // animation-duration: 6s;
      // animation-timing-function: linear;
  
        &.large-div {
          display: inline-block;
          width: 33.33%;
          flex: none;
         
      }
      &.small-div{
        width: 10%;
      }
      
      }
      img {
        width: 10rem ;
        padding: 3rem 2rem 1rem;
        &.large-img {
          width: 100% ;
          padding: 0;
          background: #000;
      }

    //   &.small-img {
    //     width: 100% ;
    //     padding: 0;
    //     background: #000;
    // }
      }

      .slick-slide{
        position: relative;
    //     &:after {
    //       right: 0;
    //       content: '';
    //       background: url("../../assets/addbutton.svg") no-repeat;
    //       width: 2rem;
    //       height: 2rem;
    //       top: 0.5rem;
    //       cursor: pointer;
    //       background-size: 1.5rem;
    //       position: absolute;
    // }
    }
  }
    &-list {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);

      border-bottom: 0.5px solid var(--white1);
      padding-bottom: 0.5rem;
      a {
        text-decoration: none;
        color: var(--white);
        // opacity: 0.6;
        // &.active {
        //   opacity: 1;
        // }
      }
    }
  }
  
}
video {
  width: 100%;
  @include respond-from(lg){
    width: auto;
  }
}
.truffle-orange span {
  background: #ff5000;
}
.pearl-order-left {
  flex-basis: 50%;
  @include respond-from(sm){
    flex-basis: 50%;
  }
}
.customise{
  &-start{
    flex-basis: 25%;
  }
  &-end{
    flex-basis: 70%;
    text-align: right;
  }
  .listing{
    margin: 0;
    column-count: 3;
  }
  &-pearl{
    overflow: hidden;
    margin-left: -12rem;
  }
  &-second{
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 128%;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    width: 50%;
    margin-bottom: 2.4rem;
    img{
      width: 6.4rem;
    }
    div{
      padding-left: 1.6rem;
    }
  }
  &-amount{
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.9rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    &-qty{
      padding: 0 1rem;
    }
  }
}
.packed{
 &-img{
   & > div{
    padding: 1rem 0.5rem;
    width: 33.33%;
   }
   img{
     width: 100%;
     //height: 30.5rem;
     border-radius: 4px;
     width: 100%;
     border-radius: 4px;
     height: 15rem;
     object-fit: cover;

    //  object-fit: cover;
    //  @include respond-from(sm){
    //   height: 150px;
    //  }
   }
 }
 &-width{
   flex-basis: 100%;
 }
 &-head{
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.9rem;
  text-align: right;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: .5rem;
 }
 &-start{
  flex-basis: 20%;
 }
 &-end{
  flex-basis: 75%;
 }
}
.packed-listing{
  .listing{
    margin: 0 !important;
  }
}
.step{
  // &-btns{
  //   height: 0;
  // }
  &-img{
    img{
      width: 100%;
    border-radius: 4px;
    }
  }
  &-list{
    ul{
      column-count: 3;
      display: block;
    }
    & > div {
      width: 100%;
      display: inline-block;
  }
  }
}

.pearl-4{
  div {
    width: 6rem !important;
    position: absolute;
    img {
      width: 100%;
      height: 6rem !important;
      border-radius: 50%;
    }
    &:first-child {
      top: 2rem;
      right: 7.4rem;
    }
    &:nth-child(2) {
      top: 7.5rem;
      right: 2rem;
    }
    &:nth-child(3) {
      bottom: 2.2rem;
      right: 7.5rem;
    }
    &:nth-child(4) {
      top: 7.5rem;
      left: 2rem;
    }
   
  }
  }
  
.pearl-6{
div {
  width: 6rem !important;
  position: absolute;
  img {
    width: 100%;
    height: 6rem !important;
    border-radius: 50%;
  }
  &:first-child {
    top: 1.2rem;
    right: 7.5rem;
  }
  &:nth-child(2) {
    top: 5.5rem;
    right: 1.5rem;
  }
  &:nth-child(3) {
    top: 13rem;
    right: 3.6rem;
  }
  &:nth-child(4) {
    bottom: 2.4rem;
    left: 3.5rem;
  }
  &:nth-child(5) {
    top: 5.5rem;
    left: 1.4rem;
  }
  
  &:nth-child(6){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
}


.pearl-12{
    div {
      width: 4.5rem !important;
      position: absolute;
      img {
        width:100%;
        height: 4.5rem !important;
        border-radius: 50%;
      }
      &:first-child {
        top: 1rem;
        right: 8.2rem;
      }
      &:nth-child(2) {
        top:2rem;
        right: 4rem;
      }
      &:nth-child(3) {
        top: 5.2rem;
        right: 1.5rem;
      }
      &:nth-child(4) {
        top: 9.4rem;
        right: .9rem;
      }
      &:nth-child(5) {
        bottom: 3.2rem;
        right: 2.5rem;
      }
      &:nth-child(6) {
        bottom: 1rem;
        right: 6rem;
      }
      &:nth-child(7) {
        bottom: 1rem;
        left: 6.2rem;
      }
      &:nth-child(8) {
        top: 13.2rem;
        left: 2.6rem;
      }
      &:nth-child(9) {
        top: 9.4rem;
        left: 0.9rem;
      }
      &:nth-child(10) {
        top: 5.2rem;
        left: 1.5rem;
      }
      &:nth-child(11) {
        top: 1.9rem;
        left: 4.1rem;
      }
      &:nth-child(12) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
}
.pearl-18{
  div {
    width: 3rem !important;
    position: absolute;
    img {
      width:100%;
      height: 3rem !important;
      border-radius: 50%;
    }
    &:first-child {
      top: 5rem;
      right: 8rem;
    }
    &:nth-child(2) {
      top: 8rem;
      right: 5.2rem;
    }
    &:nth-child(3) {
      bottom: 6.4rem;
      right: 6.2rem;
    }
    &:nth-child(4) {
      bottom: 5.4rem;
      right: 9.9rem;
    }
    &:nth-child(5) {
      bottom: 8.2rem;
      left: 5.5rem;
    }
    &:nth-child(6) {
      top: 6.2rem;
      left: 6.2rem;
    }
    &:nth-child(7) {
      top: 1.3rem;
    right: 9rem;
    }
    &:nth-child(8) {
      top: 2.7rem;
      right: 4.8rem;
    }
    &:nth-child(9) {
      top: 5.8rem;
      right: 1.9rem;
    }
    &:nth-child(10) {
      bottom: 8rem;
      right: 1.5rem
    }
    &:nth-child(11) {
      bottom: 4rem;
      right: 3.1rem;
    }
    &:nth-child(12) {
      bottom: 1.7rem;
    right: 6.8rem;
     
    }
    &:nth-child(13) {
      bottom: 1.7rem;
    left: 6.8rem;
     
    }
    &:nth-child(14) {
      bottom: 4rem;
      left: 3.3rem;
     
    }
    &:nth-child(15) {
      bottom: 8rem;
      left: 1.6rem;
     
    }
    &:nth-child(16) {
      top: 5.8rem;
      left: 2rem
     
    }
    &:nth-child(17) {
      top: 2.6rem;
      left: 5rem;
     
    }
    &:nth-child(18){
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.pearl-24{
  div {
    width: 3.2rem ;
    position: absolute;
     height: 3.2rem ;
    img {
      width:100%;
      border-radius: 50%;
    }
    &:first-child {
      top: 3.6rem;
      right: 9rem;
    }
    &:nth-child(2) {
      top: 4.5rem;
      right: 6.2rem;
    }
    &:nth-child(3) {
      top: 6.8rem;
      right: 4.2rem;
    }
    &:nth-child(4) {
      top: 9.8rem;
      right: 3.8rem;
    }
    &:nth-child(5) {
      bottom: 5.6rem;
    right: 5rem;
    }
    &:nth-child(6) {
      bottom: 4rem;
      right: 7.5rem;
    }
    &:nth-child(7) {
      bottom: 4rem;
      left: 7.3rem;
    }
    &:nth-child(8) {
      top: 12.5rem;
      left: 4.8rem;
    }
    &:nth-child(9) {
      top: 9.8rem;
      left: 3.5rem;
    }
    &:nth-child(10) {
      top: 6.8rem;
    left: 4rem;
    }
    &:nth-child(11) {
      top: 4.4rem;
      left: 5.9rem;
    }
    &:nth-child(12) {
      top: 0.5rem;
      right: 9rem;
    }
    &:nth-child(13) {
      top: 1.6rem;
    right: 4.8rem;
    }
    &:nth-child(14) {
      top: 4.8rem;
      right: 1.6rem;
    }
    &:nth-child(15) {
      top: 9rem;
    right: .6rem;
    }
    &:nth-child(16) {
      bottom: 5rem;
      right: 1.8rem;
    }
    &:nth-child(17) {
      bottom: 2rem;
      right: 4.8rem;
    }
    &:nth-child(18) {
      bottom: .8rem;
      left: 8.9rem;
    }
    &:nth-child(19) {
      top: 16.2rem;
      left: 4.6rem;
    }
    &:nth-child(20) {
      top: 13.2rem;
      left: 1.5rem;
    }
    &:nth-child(21) {
      top: 8.9rem;
      left: 0.5rem;
    }
    &:nth-child(22) {
      top: 4.8rem;
      left: 1.6rem;
    }
    &:nth-child(23) {
      top: 1.6rem;
      left: 4.5rem;
    }
    &:nth-child(24) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.pearl-36{
  div {
    width: 2.5rem;
    position: absolute;
    height: 2.5rem;
    img {
      width:100%;
      border-radius: 50%;
    }
    &:first-child {
      top: 6.5rem;
      right: 9.2rem;
    }
    &:nth-child(2) {
      top: 7.9rem;
      right: 6.8rem;
    }
    &:nth-child(3) {
      top: 10.8rem;
      right: 6.8rem;
    }
    &:nth-child(4) {
      top: 12.2rem;
      right: 9.2rem;
    }
    &:nth-child(5) {
      bottom: 8.2rem;
      right: 11.8rem;
    }
    &:nth-child(6) {
      bottom: 11rem;
    right: 11.6rem;
    }
    &:nth-child(7) {
      top: 3.6rem;
    left: 9.3rem;
    }
    &:nth-child(8) {
      top: 4rem;
      right: 6.9rem;
    }
    &:nth-child(9) {
      top: 5.5rem;
      right: 5rem;
    }
    &:nth-child(10) {
      top: 7.5rem;
      right: 3.8rem;
    }
    &:nth-child(11) {
      top: 9.9rem;
      right: 3.6rem;
    }
    &:nth-child(12) {
      top: 12.2rem;
    right: 4.4rem;
    }
    &:nth-child(13) {
      top: 13.8rem;
      right: 6rem;
    }
    &:nth-child(14) {
      bottom: 4rem;
      right: 8rem;
    }
    &:nth-child(15) {
      bottom: 4rem;
      right: 10.4rem;
    }
    &:nth-child(16) {
      bottom: 5rem;
      left: 5.8rem;
    }
    &:nth-child(17) {
      bottom: 6.7rem;
      left: 4.2rem;
    }
    &:nth-child(18) {
      bottom: 9rem;
      left: 3.6rem;
    }
    &:nth-child(19) {
      bottom: 11.4rem;
    left: 3.8rem;
    }
    &:nth-child(20) {
      top: 5.4rem;
      left: 5rem;
    }
    &:nth-child(21) {
      top: 4rem;
      left: 6.9rem;
    }
    &:nth-child(22) {
      top: 0.8rem;
      left: 9.3rem;
    }
    &:nth-child(23) {
      top: 1.4rem;
      right: 5.8rem;
    }
    &:nth-child(24) {
      top: 3.6rem;
      right: 3rem;
    }
    &:nth-child(25) {
      top: 6.6rem;
      right: 1rem;
    }
    &:nth-child(26) {
      top: 10.2rem;
    right: .8rem;
    }
    &:nth-child(27) {
    bottom: 5.4rem;
    right: 1.9rem;
    }
    &:nth-child(28) {
      bottom: 2.6rem;
      right: 4.2rem;
    }
    &:nth-child(29) {
      bottom: 1.2rem;
      right: 7.5rem;
    }
    &:nth-child(30) {
      bottom: 1.2rem;
      left: 7.5rem;
    }
    &:nth-child(31) {
      bottom: 2.6rem;
      left: 4.2rem;
    }
    &:nth-child(32) {
      bottom: 5.4rem;
      left: 2rem;
    }
    &:nth-child(33) {
      bottom: 8.7rem;
      left: 0.9rem;
    }
    &:nth-child(34) {
      top: 6.6rem;
    left: 1rem;
    }
    &:nth-child(35) {
      top: 3.6rem;
      left: 2.8rem;
    }
    &:nth-child(36){
      top: 1.5rem;
      left: 5.8rem;
    }
    &:nth-child(37) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.pearl-price-left{
  button{
  &:first-child {
    flex: 2.5;
  }
  &:nth-child(2) {
    flex: 3;
  }
  &:nth-child(3) {
    flex: 4;
  }
  &:nth-child(4) {
    flex: 5;
  }
  &:nth-child(5) {
    flex: 6;
  }
}
}
.large-div {
  .addbutton{
    right: 5rem;
    @include respond-from(sm){
      right: 1rem;
    }
   &:hover + .tooltip{
     visibility: visible;
   }
  }

    
}
.small-div {
  .addbutton{
   &:hover + .tooltip{
     visibility: visible;
   }
  }
  .tooltip {
    top: 24%;
    right: 0;
  }
    
}
.truffle-height{
  height: 100%;
  margin: 0;
}
.tooltip {
  visibility: hidden;
    display: inline-block;
    position: absolute;
    top: 30%;
    right: 2.2rem;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 1;

  } 
  .tooltiptext {
    white-space: nowrap;
    background-color:var(--white);
    color: #000;
    text-align: center;
    border-radius: .4rem;
    padding: .5rem 1rem;
    font-size: 1.4rem;
    font-weight: 600;
  }   
  .truffles-6{
    position: relative;
    .pearl-products {
      img {
        width: 100%;
      }
      &-images {
        width: 15rem;
        height: 15rem;
        position: absolute;
        right: 6.5rem;
        top: 1rem;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        .truffle-box-img{
          width: 4.7rem;
      }
      }
    } 
  }
.truffles-9{
  position: relative;
  .pearl-products {
    img {
      width: 100%;
    }
    &-images {
      width: 15rem;
      height: 15rem;
      position: absolute;
      right: 6.5rem;
      top: 1rem;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      .truffle-box-img{
        width: 4.7rem;
    }
    }
  } 
}
.truffles-12{
  position: relative;

  .pearl-products {
    img {
      width: 100%;
    }
    &-images {
      width: 15rem;
      height: 15rem;
      position: absolute;
      right: 6.5rem;
      top: 1rem;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      .truffle-box-img{
        width: 3.7rem;
    }
    }
  } 
}
.truffles-25{
  .pearl-products {
    img {
      width: 100%;
    }
    &-images {
      width: 15rem;
     // height: 15rem;
      position: absolute;
      right: 6.5rem;
      top: 1rem;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      .truffle-box-img{
        width: 2.9rem;
      }
    }
  } 
}
.step2-truffle-boxes{
width: 50rem;
    margin: 0 auto;
    @include respond-from(md){
      float: right;
    }
    @include respond-from(sm){
      overflow: hidden;
      // float: none;
      // margin: 0px 0 0 -126px !important;
    }
}
.step2-truffle-boxes{
.slick-slide.slick-active.slick-current {
  position: relative;
}
}
.small-text{
  padding: 0 0 0 2rem;
    color: var(--white);
    font-size: 1.4rem;
    //white-space: nowrap;
    margin-bottom: 2rem;
    // text-overflow: ellipsis;
    // width: 100%;
    @include line-clamp(1);
}
.checkout-list{
  .listing{
    label{
      align-items: baseline;
      span{
        top:5px !important;
      }
    }
  }
}


.pearls4{
  width: 100%;
}