.step{
    .pearl-price-left{
       img{
        padding-right: 2rem;
       }
    }
    &-top{
        position: absolute;
        // top: -5rem;
        top: -2rem;

        right: 0;
        width: 100%;
        align-items: center;
        padding: 0 2.4rem;
        // position: relative;
        // padding: 1rem 0 2rem;
        @include respond-from(xs_sm){
            position: static;
        }
    }
    &-next{
        // position: absolute;
        // top: -4.2rem;
        // z-index: 1;
        // right: 0;
        padding: 1rem 2rem;
        border: 0.5px solid #FFFFFF;
        background: #fff;
        border-radius: 8px;
        position: absolute;
        right: 2.5rem;
        z-index: 1;
        color: var(--dark);
        img{
            filter: brightness(0);
        }
        @include respond-from(xs_sm){
            top: -5rem;
        }
    }
    
}
.buffer{
    width: 3.5rem;
}
.load{
    width: 3rem;
}
.bookmark{
    width: 2.5rem;
}