.shop {
  background: url(../../assets/banner.svg) no-repeat;
  height: 412px;
  position: relative;
  margin-top: -144px;
  background-size: cover;
  @include respond-from(sm) {
    height: 194px;
    margin-top: -69px;
  }
  &-section {
    padding-top: 5rem;
  }
  &-banner {
    h2 {
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.8rem;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--white);
      width: 100%;
      text-align: center;
      padding-top: 7.9rem;
      @include respond-from(md){
        padding-top: 70px;
      }
      @include respond-from(sm){
        font-size: 24px;
        padding: 0;
      }
    }
  }
  &-section {
    &-inner {
      width: 33.33%;
      padding: 1rem;
      @include respond-from(sm) {
        width: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        border-radius: 4px;
        height: 504px;
        @include respond-from(sm) {
          height: 142px;
          border-radius: 8px;
        }
      }
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        padding: 1rem 0 3rem;
        color: var(--white);
        @include respond-from(sm) {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.08em;
          padding: 16px 0;
        }
      }
    }
    &-change {
      margin: 0 -1rem;
      & > div {
        &:nth-child(5) {
          width: 100%;
        }
      }
      img{
        transition: transform .2s;
        &:hover {
          transform: scale(1.03);

          box-shadow: 9px 3px 14px 8px rgba(0, 0, 0, 0.4);
      }
      }
    }
  }
}
.classic-box {
  .personalised-section-inner {
    width: 50%;
    &:nth-child(5) {
      width: 100%;
    }
  }
}
.classic {
  background: url(../../assets/claasic3.png) no-repeat !important;
}
.product {
  &-first {
    position: relative;
    z-index: 1;
    right: -0.4rem;
  }
  &-space {
    button {
      padding: 0 0.5rem;
      
      &:hover{
        opacity: 1;
      }
      &:first-child{
        
          width: 5rem;
        
      }
      &:nth-child(2){
        
          width: 8rem;
        
      }
      &:nth-child(3){
          width: 10rem;
        
      }
      &:nth-child(4){
          width: 12rem;
        
      }
      &:last-child{
        width: 16rem;
      }
      @include respond-from(sm) {
        padding: 0 10px;
      }
    }
  }
  &-rect {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    border-bottom: 0.5px solid #ffffff;
    @include respond-from(sm) {
      font-size: 12px;
    }
    img {
      padding-right: 0.8rem;
      @include respond-from(sm) {
        padding-right: 16px;
      }
    }
  }
  &-message {
    &-img {
      margin-top: 1.6rem;
      flex-wrap: wrap;
      row-gap: 1rem;
      column-gap: .5rem;
      img {
        width: 15%;
        cursor: pointer;
        object-fit: cover;
        // opacity: 

        // &:not(:last-child) {
        //   padding-right: 0.5rem;
        //   @include respond-from(sm) {
        //     padding-right: 10px;
        //   }
        // }
      }
    }
    textarea {
      resize: none;
      border: 0.5px solid var(--white1);
      border-radius: 8px;
      width: 100%;
      background: none;
      padding: 2rem;
      // text-transform: uppercase;
      margin-top: 1.6rem;
      color: var(--white);
      @include respond-from(sm) {
        margin: 16px 0;
        height: 74px;
      }
    }
  }
  &-second {
    // position: relative;
    // left: -7px;
    // z-index: 0;
    // width: 22rem !important;
  }
}

.square-box,.square-box-left {
  button {
   
    &:first-child{
      
        width: 10rem;
      
    }
    &:nth-child(2){
      
        width: 12rem;
      
    }
    &:nth-child(3){
        width: 14rem;
      
    }
    &:nth-child(4){
        width: 16rem;
      
    }
    // @include respond-from(sm) {
    //   padding: 0 10px;
    // }
  }
}
.padding-0{
  padding: 0 !important;
}