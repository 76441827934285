.main {
  background: var(--dark);
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  //overflow: hidden;
  flex-flow: column;
}
.breadcrumb {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #f2f2f2;
  padding: 1.5rem  0;
  display: inline-block;
  a{
    color: #f2f2f2; 
  }
  @include respond-from(sm){
   // display: none;
  }
&-main{
  position: relative;
  z-index: 1;
}
  button {
    background: none;
    border: none;
  }
}

// body{
//    -webkit-backface-visibility: hidden;
// backface-visibility: hidden;
// overflow-x: hidden;
// }