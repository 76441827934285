@font-face {
    font-family: Proxima Nova; font-display: swap;
    src: url(../font/Proxima/regular.otf);
  }
@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'base/base';
@import 'base/utilities';

@import 'components/layout';
@import 'components/header';
@import 'components/footer';
@import 'components/buttons';
@import 'components/select';

@import 'pages/home';
@import 'pages/shop';
@import 'pages/personlised';
@import 'pages/login';
@import 'pages/truffle';
@import 'pages/packedsingleprod';
@import 'pages/pearl';
@import 'pages/cart';
@import 'pages/step2';
@import 'pages/account';
@import 'pages/checkout';
@import 'pages/bill';

