@use "sass:math";

@mixin grid-generator($size, $grid-columns) {
    @for $i from 1 through $grid-columns {
      .col-#{$size}-#{$i} {
        width: percentage($i / $grid-columns);
        float: left;
      }
    }
  }

@mixin respond-from($media) {
  @if $media == xs-xs {
    @media (min-width: 0) and (max-width: 375px) {
      @content;
    }
  }
  @if $media == xs {
    @media (min-width: 0) and (max-width: 420px) {
      @content;
    }
  } @else if $media == md_sm {
    @media (min-width: 421px) and (max-width: 600px) {
      @content;
    }
  } @else if $media == xs_small {
    @media (max-width: 360px) {
      @content;
    }
  } @else if $media == xs_sm {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $media == sm {
    @media (max-width: 767px) {
      @content;
    }
  } 
  // @else if $media == md-small {
  //   @media (max-width: 768px) {
  //     @content;
  //   }
  // } 
  @else if $media == no-touch-screen-media {
    @media (hover: hover) {
      @content;
    }
  } @else if $media == touch-screen-media {
    @media (hover: none) and (pointer: coarse) {
      @content;
    }
  } @else if $media == md_lg {
    @media (min-width: 901px) and (max-width: 1100px) {
      @content;
    }
  } @else if $media == md {
    @media (max-width: 900px) {
      @content;
    }
  } @else if $media == md-xxl {
    @media (max-width: 1000px) {
      @content;
    }
  } @else if $media == md-xl {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $media == lg {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $media == lg-md {
    @media (min-width: 901px){
      @content;
    }
  } @else if $media == xl {
    @media (min-width: 1201px) {
      @content;
    }
  } @else if $media == lg-port {
    @media (min-width: 1800px) {
      @content;
    }
  }
  @else if $media == xl-port {
    @media (min-width: 2500px) {
      @content;
    }
  } @else if $media == xl-port-port {
    @media (min-width: 3000px) {
      @content;
    }
  } @else if $media == xl-lg {
    @media (min-width: 4500px) {
      @content;
    }
  }
}

