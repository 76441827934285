.footer {
  // padding: 5rem 0;
  background: var(--dark);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.8;
  letter-spacing: 0.08em;
  text-transform: capitalize;
  margin-top: auto;
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
  }

  &-term {
    padding-bottom: 2rem;
  }
  &-input {
    border: 1px solid #fff;
    max-width: 35rem;
    padding: 1rem;
    input {
      width: 100%;
      border: none;

      background: none;
      font-weight: 600;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
      letter-spacing: 0.08em;
      // text-transform: capitalize;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  h4 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    padding-bottom: 0.5rem;
  }
  &-social {
    margin-top: 10px;
    img {
      padding-right: 1rem;
    }
  }
  &-inner {
    padding: 6rem 0 3.2rem 0;
    width: 100%;
    // max-width: 120rem;
    margin: 0 auto 0;
    border-top: 0.5px solid #ffffff;

    @include respond-from(sm) {
      padding: 3rem 0;
      border: none;
    }
    &-logo {
      @include respond-from(sm) {
        margin-bottom: 20px;
      }
    }
  }
  &-main {
    &-inner {
      @include respond-from(sm) {
        margin-bottom: 20px;
      }
    }
  }
  &-address {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      img {
        margin-right: 8px;
        width: 16px;
      }
    }
    a {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  &-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    letter-spacing: 0.08em;

    color: #ffffff;
    h4 {
      text-transform: uppercase;
      font-size: 12px;
    }
    img {
      margin-right: 8px;
      width: 16px;
      align-self: baseline;
      padding-top: 0.4rem;
      min-width: 16px;
    }
  }
  &-padding {
    padding: 0 4rem;
    @include respond-from(lg) {
      padding: 0 2rem;
    }
    @include respond-from(sm) {
      padding: 50px 1rem 0;
    }
  }
  &-center {
    .flex {
      & > div {
        span {
          display: block;
        }
      }
    }
  }
  @include respond-from(sm) {
    &-first {
      padding: 0px 5rem 50px;
      max-width: 320px;
      margin: 0 auto;
      border-bottom: 0.5px solid #ffffff;
    }
    &-center {
      text-align: center;
      .footer-text {
        margin-bottom: 30px;
        img {
          padding: 0;
          align-self: center;
        }
      }
      h4 {
        text-align: center;
        padding-bottom: 14px;
      }

      .flex {
        justify-content: center;
        margin-bottom: 0.5rem;
        & > div {
          text-align: left;
          span {
            display: inline-block;
            padding-left: 0.4rem;
          }
        }
      }
    }
  }
  &-inputs {
    input {
      border: 0.5px solid #ffffff;
    background: transparent;
    padding: 1rem;
    width: 100%;
    color: #fff;
    max-width: 26rem;
    }
    button {
      visibility: hidden;
      position: relative;
      margin: -2rem;
      width: 1rem;
      &::before {
        content: "";
        background: url(../../assets/leftArrow.svg) no-repeat;
        position: absolute;
        color: red;
        right: 0;
        visibility: visible;
        background-size: 1rem;
        width: 1rem;
        height: 2rem;
      }
    }
  }
}
.footer-leftBorder {
  border: 0.5px solid #ffffff;
  border-top: none;
  border-bottom: none;
  @include respond-from(sm) {
    border: none;
  }
}
