.custom-select {
  max-width: 30rem;
  width: 100%;
  & .react-dropdown {
    &-select {
      border-top: unset !important;
      border-left: unset !important;
      border-right: unset !important;

      font-size: 1.4rem !important;
      &:focus, &:focus-within {
        box-shadow: none;
        border-color: var(--white);
    }
    &:hover{
      border-color: var(--white);
    }
      &-dropdown {
        background-color: var(--dark) !important;
        font-size: 1.2rem !important;

        border-top: unset !important;
        border-left: unset !important;
        border-right: unset !important;
        // color: var(--white) !important;
        & span {
          color: var(--white) !important;
          opacity: unset !important;
          padding: 1.5rem !important;
        }
      }

      &-content {
        color: var(--white) !important;

        & input {
            opacity: unset !important;
            padding: 1rem !important;
            font-size: 1.4rem !important;
            color: var(--white) !important;
            display: none;
            &::placeholder{
                color: var(--white) !important;
            }
          }

        & span {
          opacity: unset !important;
        }
      }
    }
  }
}
