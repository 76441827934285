.header {
  // border-bottom: .05rem solid rgba(255,255,255,.4);
  padding: 2.5rem 0;
  letter-spacing: 0.08em;
  text-transform: capitalize;
  color: #fff;
  font-size: 1.4rem;
  //border-bottom: 0.5px solid var(--white1);
  @include respond-from(sm) {
    padding: 15px 0;
  }

  &-searchs {
    padding-bottom: 16px;
    display: none;
    @include respond-from(sm) {
      display: flex;
    }
    img {
      width: 24px;
    }

    input {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #ffffff;
      background: none;
      border: none;

      &::placeholder {
        color: #ffffff;
        opacity: 1;
      }

      &:hover,
      &:focus {
        border-bottom: 1px solid #fff;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }
  &-search {
    position: relative;
    @include respond-from(sm) {
      display: none;
    }
    &-dropdown {
      background: var(--dark);
      opacity: 0.9;
      position: absolute;
      top: 3.5rem;
      // padding: 2rem;
      width: 27.7rem;
      text-align: center;
      left: 54px;
      a{
        color: var(--white);
        text-decoration: none;
      }
      li {
        padding: 1.6rem 0;
        border: 0.5px solid #ffffff;
        border-left: 0;
        border-right: 0;
      }
    }
    img {
      width: 3.6rem;
      filter: brightness(100);
    }

    input {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 17px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #ffffff;
      background: none;
      border: none;
@include respond-from(sm){
  font-size: 14px;
}
      &::placeholder {
        color: #ffffff;
        opacity: 1;
      }

      &:hover,
      &:focus {
        border-bottom: 1px solid #fff;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  .basket {
    @include respond-from(sm) {
      display: none;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-user {
    margin: 0 1rem;
  }

  &-dropdown {
    position: relative;
    display: inline-block;
    transition: 0.3s;
    z-index: 1;

    @include respond-from(sm) {
      display: none;
    }

    &:hover {
      .header-dropdown-content {
        display: block;
      }
    }

    &-content {
      background: var(--dark);
      display: none;
      position: absolute;
      width: 11.7rem;
      z-index: 1;
      padding: 1rem;
      border-radius: 1.6rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: var(--white);
      left: 0;

      li {
        &:not(:last-child) {
          margin-bottom: 0.8rem;
        }
      }
    }
  }

  &-order {
    position: fixed;
    right: 0;
    max-width: 38.8rem;
    z-index: 0;
    transition: 0.5s;
    // height: calc(100vh - 9rem);
    transition-delay: 0s;
    background: var(--dark);
    display: flex;
    flex-flow: column;
    color: var(--white);
    height: calc(100vh - 89px);
    top: 89px;
    padding: 24px;
    width: 100%;
    border-left: 1.2rem solid var(--theme);

    // @include respond-from(lg) {
    //   height: calc(100vh - 9rem);
    //   top: 9rem;
    // }

    // @include respond-from(md-xl) {
    //   height: calc(100vh - 9.5rem);
    //   top: 9.5rem;
    // }
    @include respond-from(sm) {
      height: 100vh;
      z-index: 1;
      top: 0;
      width: 100%;
      max-width: 320px;
      right: 0;
    }
    &-scroll {
      overflow: auto;
      padding-right: 1rem;
    padding-bottom: 6rem;
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #e6e6e6;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    .btn-checkout {
      width: 100%;
      color: #fff;
      border-color: #fff;
    }

    .cart-right {
      position: relative;

      // &:after {
      //   background: linear-gradient(
      //     180deg,
      //     rgba(25, 26, 28, 0.85) 51.47%,
      //     rgba(25, 26, 28, 0) 189.29%
      //   );
      //   transform: rotate(-180deg);
      //   content: "";
      //   width: 100%;
      //   height: 4.9rem;
      //   position: absolute;
      //   top: -4.9rem;
      // }
    }

    &-sum {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: rgb(255, 255, 255);
      opacity: 0.5;
    }

    &-box {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);
    }

    &-left {
      flex-basis: 12rem;

      & .prod_img{
        height: 10rem;
        width: 100%;
        // object-fit: contain;
        object-fit: cover;
      }

      img {
        width: 100%;
      }

      .table-edit {
        margin-top: 0.8rem;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 160%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
        @include respond-from(sm) {
          font-size: 14px;
        }

        button {
          background: none;
          border: none;
        }
      }
    }

    &-price {
      border-top: 0.05rem solid var(--white1);
    }

    &-person {
      font-style: italic;
      font-weight: 600;
      font-size: 10px;
      line-height: 1.6;
      letter-spacing: 0.09em;
      color: rgba(255, 255, 255, 0.7);
    }

    &-rate {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-align: right;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);
    }

    &-right {
      flex: 1;
      padding-left: 3rem;
      display: flex;
      flex-flow: column;
      .table-qty {
        button {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.09em;
          text-transform: uppercase;
          color: var(--white);
        }

        span {
          padding: 0 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          letter-spacing: 0.09em;
          text-transform: uppercase;
          color: var(--white);
        }
      }

      ul {
        flex: 1 1;
  @include respond-from(sm){
    max-height: 16rem;
    overflow: auto;
  }
      }

      li {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &-list {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);

      .table-price {
        span {
          width: 0.862rem;
          height: 0.862rem;
          display: inline-block;
          border-radius: 50%;
          background: #c4c4c4;
          margin-right: 0.5rem;
        }
      }
    }

    &-border {
      padding-bottom: 1rem;
      border-bottom: 0.075rem solid rgba(255, 255, 255, 0.5);
    }
  }

  &-menu {
    position: fixed;
    left: 0;
    max-width: 40.9rem;
    z-index: 0;
    transition: 0.5s;
    height: calc(100vh - 8.8rem);
    transition-delay: 0s;
    background: var(--dark);
    display: flex;
    flex-flow: column;
    color: var(--white);
    top: 8.8rem;
    padding: 24px;
    width: 100%;

    @include respond-from(lg) {
      height: calc(100vh - 9rem);
      top: 9rem;
    }

    @include respond-from(md-xl) {
      height: calc(100vh - 9.5rem);
      top: 9.5rem;
    }
    @include respond-from(sm) {
      width: 100%;
      max-width: 320px;
      height: 100vh;
      top: 0;
      z-index: 1;
    }
    li {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.08em;
      color: var(--white);
      @include respond-from(sm){
        font-size: 16px;

      }
      &:not(:last-child) {
        padding-bottom: 1.6rem;
        @include respond-from(sm){
          padding-bottom: 16px;
          
        }
      }
      &:hover {
        color: #f05a27;
        cursor: pointer;
      }
      img {
        padding-left: 1rem;
      }
    }

    &-input {
      max-width: 221px;
    }
  }

  &-terms {
    li {
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: var(--white);
      padding-bottom: 10px !important;
    }
  }
}

.toggle {
  @include respond-from(sm) {
    display: none;
  }

  input {
    display: none;
  }
}

.dark-mode {
  @include respond-from(sm) {
    display: none;
  }
}
.dark-modes {
  display: none;
  @include respond-from(sm) {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.08em;
  }
  .toggle {
    display: block;
  }
}
.toggle_btn {
  position: relative;
  background: none;
  width: 43px;
  border: 1px solid #fff;
  height: 19px;
  display: inline-block;
  cursor: pointer;
  border-radius: 37px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;

  &::before {
    content: "";
    background: #fff;
    position: absolute;
    display: inline-block;
    width: 14px;
    border-radius: 50%;
    height: 14px;
    transform: translate(-23px, 0.5px);
    top: 1.4px;
    right: 2px;
  }
}

.toggle input:checked + label::before {
  transition: 0.4s;
  transform: translateX(0px);
}
.darks{
  display: none;
}
.scroll-change{
  width: 80%;
  overflow: auto;
  white-space: nowrap;
}