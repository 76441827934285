.sp {
  &-top {
    display: flex;
    flex-wrap: wrap;
    &__left {
      height: 100%;
      // flex-basis: 55%;
      &-img{
        img{
          width: 100%;
          height: 52rem;
          object-fit: cover;
          border-radius: 4px;
          @include respond-from(sm){
            height: unset;
          }
        }
      }
    }
    &__inner {
      margin: 0 -0.5rem;
      // img {
      //   padding: 1rem 0.5rem;
      // }
    }
    &__right {
      height: 100%;
      padding-left: 2rem;
      // background-color:orangered;
      // flex: 1;

      // text-align: center;
        @include respond-from(sm){
          padding-left: 0;
        }
      h3 {
        font-weight: 600;
        font-size: 4rem;
        line-height: 49px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--white);
        span {
          font-size: 2rem;
        }
      }
    }

    &__content {
      color: var(--white);

      h4 {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 29px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: var(--white);
      }

      p {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        letter-spacing: 0.08em;
      }
    }
  }

  &-unit-price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    &__units {
      flex-basis: 35%;

      & .qty {
        display: flex;
        padding-top: 0.5rem;
        font-size: 3.2rem;
        font-weight: 600;
        color: var(--white);

        & span {
          padding: 0 1.5rem;
        }
      }
    }

    &__price {
      flex-basis: 60%;
      text-align: end;

      & .amount {
        padding-top: 0.5rem;
        font-size: 3.2rem;
        font-weight: 600;
        color: var(--white);
        @include respond-from(md){
          font-size: 20px;
        }
        span {
          display: flex;
          align-items: center;
          flex: none;
          opacity: 0.5;
          &.active{
            opacity: 1;
          }
        }

       & .gift-voucher-dots {
          display: inline-block;
          width: 0.4rem;
          height: 0.4rem;
          background: #fff;
          border-radius: 50%;
          margin: 0 0.5rem;
        }
      }
    }

    &__head {
      & .heading {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }

  &-message {
    .heading {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: var(--white);
      padding: 1rem 0;
    }
  }
}
.chilli {
  &-img{
    &-two{
      img{
        height: 54.5rem;
        object-fit: cover;
        border-radius: 4px;
        @include respond-from(sm){
          height: auto;
        }
      }
    }
   img{
    width: 100%;
   }
  }
  &-price {
    &-end {
      text-align: left;
      flex-basis: 75%;
    }

    &-unit {
      text-align: right;
      flex-basis: 20%;
      .qty {
        justify-content: end;
      }
    }
  }
  &-para {
    font-style: italic;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0.09em;
    color: var(--white);
  }
  &-head {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    border-bottom: 0.5px solid var(--white1);
    padding: 1rem 0;
  }
  &-text {
    font-weight: 600;
    font-size: 32px;
    line-height: 1;
    display: flex;
    align-items: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--white);
    padding: 1rem 0;
    span {
      width: 0.4rem;
      height: 0.4rem;
      background: var(--white);
      border-radius: 50%;
      margin: 0 1rem;

    }
  }
  &-main {
    .listing {
    width: 100%;
      padding: 1rem 0;
      ul{
        columns: 3;
      }
    }
    textarea {
      border: 0.5px solid var(--white1);
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      background: none;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.09em;
    //   text-transform: uppercase;
    color:var(--white);
     // color: rgba(255, 255, 255, 0.4);
      padding: 2rem;
      height: 12.5rem;
      // &::placeholder{
      //   color:var(--white);
      // }
    }
    .add-btn {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 22px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #ffff;
      border: 0.5px solid var(--white1);
      border-radius: 8px;
      background: none;
      padding: 1.6rem;
      img {
        padding-right: 1.6rem;
      }
    }
  }
}


.error{
  padding-bottom: 3rem;
  &-main{

color: #FFFFFF;
 
  h3{
    font-weight: 600;
font-size: 40px;
line-height: 4.9rem;
letter-spacing: 0.09em;
text-transform: uppercase;
margin-bottom: 10px;
span{
  font-size: 24px;
}
  }
  p{
    font-weight: 600;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.08em;
margin-bottom: 20px;
  }
 &-right{
  img{
    height: 465px;
    object-fit: cover;
    width: 100%;
  }
 }
}
&-para{
  font-weight: 600;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.08em;

}

}